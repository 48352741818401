import { AUTH_MODULE } from "@/core/config/role.config";

const grouproutes = {
  name: "Group",
  path: "group",
  component: () => import("./Group.vue"),
  children: [
    {
      path: "navigation-menu",
      name: "Navigation Menu Group",
      component: () => import("./navigationmenu/NavigationMenu.vue"),
      meta: {
        requiresAuth: true,
        roles: [
          ...AUTH_MODULE.GROUP_SMS,
          ...AUTH_MODULE.GROUP_FINANCE_PRICING_UPLOAD,
          ...AUTH_MODULE.GROUP_MY_INSIGHTS,
          ...AUTH_MODULE.GROUP_SERVICE_LEVEL_REPORTS,
          ...AUTH_MODULE.GROUP_MSC,
          ...AUTH_MODULE.GROUP_SALES_DYNAMICS_CSV_UPLOAD
        ]
      }
    },
    // SMS TOOL
    {
      path: "sms-tool/sms",
      name: "SMS",
      component: () => import("./sms-tool/sms/SMS.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_SMS
      }
    },
    // FINANCE
    {
      path: "finance/customer-pricing",
      name: "Customer Pricing Rules",
      component: () => import("./finance/pricing/CustomerPricing.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_FINANCE_PRICING_UPLOAD
      }
    },
    {
      path: "finance/accenture-standard-pricing",
      name: "Accenture Standard Pricing Rules",
      component: () =>
        import("./finance/sentia-standard/SentiaStandardPricing.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_FINANCE_PRICING_UPLOAD
      }
    },
    // MANAGE SERVICE CONNECTOR
    {
      path: "managed-services-connector/tags",
      name: "MSC Tags",
      component: () => import("./manage_service_connector/tags/Tags.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MSC
      }
    },
    {
      path: "managed-services-connector/customers",
      name: "MSC Blacklisted Customers",
      component: () =>
        import("./manage_service_connector/customers/Customers.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MSC
      }
    },
    {
      path: "managed-services-connector/blacklisted-resources",
      name: "MSC Blacklisted Resources",
      component: () =>
        import(
          "./manage_service_connector/blacklisted_resources/BlacklistedResources.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MSC
      }
    },
    {
      path: "managed-services-connector/blacklisted-resources/pre-storage",
      name: "MSC Blacklisted Resources Pre-Storage",
      component: () =>
        import(
          "./manage_service_connector/blacklisted_resources/pre_storage/PreStorage.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MSC
      }
    },
    {
      path: "managed-services-connector/blacklisted-resources/pre-validate",
      name: "MSC Blacklisted Resources Pre-Validate",
      component: () =>
        import(
          "./manage_service_connector/blacklisted_resources/pre_validate/PreValidate.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MSC
      }
    },
    {
      path: "managed-services-connector/data-quality-issues",
      name: "MSC Data Quality Issues",
      component: () =>
        import(
          "./manage_service_connector/data_quality_issues/DataQualityIssues.vue"
        ),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MSC
      }
    },
    // REPORTING
    {
      path: "reporting/service-management-report",
      name: "Service Management Reports",
      component: () =>
        import("./reporting/service_management_report/Overview.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_SERVICE_LEVEL_REPORTS
      }
    },
    {
      path: "reporting/my-insights",
      name: "My Insights",
      component: () => import("./reporting/insights/Insights.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MY_INSIGHTS
      }
    },
    {
      path: "reporting/license-report",
      name: "License Report",
      component: () => import("./reporting/license_report/LicenseReport.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MY_INSIGHTS
      }
    },
    {
      path: "reporting/premium-report",
      name: "Premium Report",
      component: () => import("./reporting/premium_report/PremiumReport.vue"),
      meta: {
        requiresAuth: true,
        roles: AUTH_MODULE.GROUP_MY_INSIGHTS
      }
    }
  ]
};

export { grouproutes };
