const AUTH_MODULE = {
  BE_CRITICALS: [
    "role-sg-sala-query_opsgenie_alerts",
    "role-sg-sala-query_servicenow_accounts",
    "role-sg-sala-query_servicenow_configuration_items",
    "role-sg-connect-mutation_opsgenie_workflow_alert_ack"
  ],
  BE_AVAILABILITY_REPORTS: [
    "role-sg-connect-mutation_availability_reporting",
    "role-sg-connect-query_availability_reporting"
  ],
  GROUP_MY_INSIGHTS: ["role-sg-connect-query_power_bi_reports"],
  GROUP_SMS: ["role-sg-sala-mutation_aws_sms"],
  GROUP_FINANCE_PRICING_UPLOAD: ["role-sg-sala-query_frontend_pre_signed_urls"],
  GROUP_SERVICE_LEVEL_REPORTS: ["role-sg-connect-query_service_level_reports"],
  GROUP_MSC: ["role-sg-connect-query_msc"],
  GROUP_SALES_DYNAMICS_CSV_UPLOAD: ["role-sg-sala-mutation_dynamics365_quotes"],
  IO_ACCOUNTS: [
    "role-sg-connect-query_dynamics365_accounts",
    "role-sg-sala-query_servicenow_accounts",
    "role-sg-connect-mutation_dynamics365_accounts"
  ],
  IO_CONTACTS: [
    "role-sg-sala-query_dynamics365_contacts",
    "role-sg-sala-query_okta_users",
    "role-sg-sala-mutation_workflow_init_sync_contact"
  ]
};

export { AUTH_MODULE };
